import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import huddlzLogo from '../images/logo.png';
import '../styles/Navbar.css';

function Navbar() {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    useEffect(() => {
        const handleScroll = () => {
            if (click) setClick(false);
        };

        const handleClickOutside = (event) => {
            if (click && !event.target.closest('.navbar-container')) {
                setClick(false);
            }
        };

        // Event listeners
        document.addEventListener('scroll', handleScroll);
        document.addEventListener('click', handleClickOutside);

        // Cleanup function
        return () => {
            document.removeEventListener('scroll', handleScroll);
            document.removeEventListener('click', handleClickOutside);
        };
    }, [click]);

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <Link to="/" className="navbar-logo">
                    <img src={huddlzLogo} alt="Huddlz" className="navbar-logo-img" />
                </Link>
                <div className="menu-icon" onClick={handleClick}>
                    <FontAwesomeIcon icon={click ? faTimes : faBars} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className="nav-item">
                        <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                            Home
                        </Link>
                    </li>
                    <li className="nav-item">
                        <a href="https://migrate.huddlz.xyz/abouthuddlz" target="_blank" rel="noopener noreferrer" className="nav-links" onClick={closeMobileMenu}>
                            About Huddlz
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="https://migrate.huddlz.xyz/" target="_blank" rel="noopener noreferrer" className="nav-links" onClick={closeMobileMenu}>
                            Migrate
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;
