import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import HUDLTokenABI from '../contracts/HUDLToken.json';
import HuddlzDiceGameABI from '../contracts/HuddlzDiceGame.json';
import '../styles/DiceGame.css';

const hudlTokenAddress = process.env.REACT_APP_HUDL_TOKEN_ADDRESS;
const huddlzDiceGameAddress = process.env.REACT_APP_HUDDLEZ_GAME_ADDRESS;

function formatAddress(address) {
    return address ? `${address.substring(0, 6)}...${address.substring(address.length - 4)}` : '';
}

function DiceGame() {
    const [web3, setWeb3] = useState(null);
    const [account, setAccount] = useState('');
    const [betAmount, setBetAmount] = useState('');
    const [gameMessage, setGameMessage] = useState({ message: '', isError: false });
    const [transactionHash, setTransactionHash] = useState('');
    const [displayTransaction, setDisplayTransaction] = useState(false);
    const [betOutcome, setBetOutcome] = useState('');
    const [rolledNumber, setRolledNumber] = useState('');
    const [latestBetId, setLatestBetId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [betOption, setBetOption] = useState('');
    const [userBalance, setUserBalance] = useState('0');
    const [isApproved, setIsApproved] = useState(false);

    useEffect(() => {
        if (typeof window.ethereum !== 'undefined') {
            const web3Instance = new Web3(window.ethereum);
            setWeb3(web3Instance);
            web3Instance.eth.requestAccounts()
                .then(accounts => {
                    setAccount(accounts[0]);
                })
                .catch(err => {
                    console.error("Failed to load accounts", err);
                });
        }
    }, []);
    // Empty dependency array means this runs once on mount

    useEffect(() => {
        // Separate effect to fetch user balance when the account is set or changed
        if (account) {
            fetchUserBalance();
        }
    }, [account]); // Depend on account

    useEffect(() => {
        // This effect is for fetching bet results
        if (web3 && account && latestBetId) {
            setIsLoading(true);
            fetchBetResult(latestBetId, 0); // Pass retryCount as 0 initially
        }
    }, [web3, account, latestBetId]); // Depend on web3, account, and latestBetId to trigger fetching bet results

    const connectWallet = async () => {
        if (typeof window.ethereum === 'undefined') {
            displayMessage("Ethereum wallet is not detected. Please install MetaMask or use a dApp browser.", true);
            return;
        }

        // Render fallback UI if window.ethereum is not available
        if (typeof window.ethereum === 'undefined') {
            return (
                <div className="DiceGame">
                    <p>Non-Ethereum browser detected. Please use a Web3-enabled browser or install MetaMask.</p>
                </div>
            );
        }

        try {
            await window.ethereum.request({ method: 'eth_requestAccounts' });
            const chainId = await window.ethereum.request({ method: 'eth_chainId' });

            if (chainId !== '0x38') {
                try {
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: '0x38' }],
                    });
                } catch (switchError) {
                    if (switchError.code === 4902) {
                        try {
                            await window.ethereum.request({
                                method: 'wallet_addEthereumChain',
                                params: [{
                                    chainId: '0x38',
                                    chainName: 'BSC Mainnet',
                                    nativeCurrency: {
                                        name: 'BNB',
                                        symbol: 'BNB',
                                        decimals: 18,
                                    },
                                    rpcUrls: ['https://bsc-dataseed.binance.org/'],
                                    blockExplorerUrls: ['https://bscscan.com/'],
                                }],
                            });
                        } catch (addError) {
                            displayMessage("Failed to add BSC Mainnet.", true);
                        }
                    } else {
                        displayMessage("Failed to switch to BSC Mainnet.", true);
                    }
                }
            }

            const accounts = await window.ethereum.request({ method: 'eth_accounts' });
            setAccount(accounts[0]);
        } catch (error) {
            displayMessage("Failed to connect wallet.", true);
        }
    };

    const connectWalletAndCheckNetwork = async () => {
        if (!window.ethereum) {
            displayMessage("Ethereum wallet is not available", true);
            return;
        }

        try {
            // Request account access
            await window.ethereum.request({ method: 'eth_requestAccounts' });

            // Check current network
            const chainId = await window.ethereum.request({ method: 'eth_chainId' });
            if (chainId !== '0x38') {
                // Attempt to switch to the BSC Mainnet
                try {
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: '0x38' }], // Hexadecimal value of 56
                    });
                } catch (switchError) {
                    // Handle errors, such as the user rejecting the request
                    displayMessage("Please switch to the BSC Mainnet manually.", true);
                    return;
                }
            }

            // Successfully connected and on the right network
            const accounts = await window.ethereum.request({ method: 'eth_accounts' });
            setAccount(accounts[0]);
            displayMessage("Wallet connected.", false);
        } catch (error) {
            displayMessage("Failed to connect wallet.", true);
        }
    };

    useEffect(() => {
        // Set up an event listener for accounts changing
        const handleAccountsChanged = (accounts) => {
            if (accounts.length === 0) {
                // MetaMask is locked or the user has not connected any accounts
                console.log('Please connect to MetaMask.');
            } else if (accounts[0] !== account) {
                setAccount(accounts[0]);
            }
        };

        // Set up an event listener for network changes
        const handleChainChanged = (_chainId) => {
            connectWalletAndCheckNetwork(); // Re-check network and connect wallet
        };

        window.ethereum.on('accountsChanged', handleAccountsChanged);
        window.ethereum.on('chainChanged', handleChainChanged);

        // Cleanup event listeners on component unmount
        return () => {
            window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
            window.ethereum.removeListener('chainChanged', handleChainChanged);
        };
    }, [account]);

    // Check if the user has approved the contract to spend tokens on their behalf
    const checkApproval = async () => {
        if (!account || !web3) return;

        const tokenContract = new web3.eth.Contract(HUDLTokenABI, hudlTokenAddress);
        const allowance = await tokenContract.methods.allowance(account, huddlzDiceGameAddress).call();

        // Convert allowance to a more manageable number if needed, or directly compare
        setIsApproved(allowance > 0);
    };

    // Call checkApproval whenever account or web3 changes
    useEffect(() => {
        checkApproval();
    }, [account, web3]);

    useEffect(() => {
        if (web3 && account && latestBetId) {
            setIsLoading(true); // Indicate loading before fetching result
            fetchBetResult(latestBetId, 0); // Initial call with retryCount 0
        }
    }, [web3, account, latestBetId]);

    const displayMessage = (message, isError = false, duration = 5000) => {
        setGameMessage({ message, isError });
        setTimeout(() => setGameMessage({ message: '', isError: false }), duration);
    };

    const fetchUserBalance = async () => {
        if (!web3 || !account) return;

        const tokenContract = new web3.eth.Contract(HUDLTokenABI, hudlTokenAddress);
        try {
            const balance = await tokenContract.methods.balanceOf(account).call();
            const formattedBalance = web3.utils.fromWei(balance, 'ether');
            setUserBalance(parseFloat(formattedBalance).toFixed(2)); // Convert to Ether from Wei and format
        } catch (error) {
            console.error("Error fetching user balance:", error);
        }
    };

    const handleApprove = async () => {
        if (!account || !web3 || parseFloat(betAmount) < 100) {
            displayMessage("Minimum bet amount is 100 HUDL tokens.", true);
            return;
        }

        const tokenContract = new web3.eth.Contract(HUDLTokenABI, hudlTokenAddress);
        const maxApproval = "115792089237316195423570985008687907853269984665640564039457584007913129639935";

        try {
            await tokenContract.methods.approve(huddlzDiceGameAddress, maxApproval).send({ from: account });
            setIsApproved(true);
            displayMessage("Approval successful. You can now place your bet.");
        } catch (error) {
            displayMessage("Error during approval. Please try again.", true);
        }
    };

    const handlePlaceBet = async (option) => {
        const maxBetAmount = 10000;
        if (parseFloat(betAmount) < 100 || parseFloat(betAmount) > maxBetAmount) {
            displayMessage(`Bet amount must be between 100 and ${maxBetAmount} HUDL tokens.`, true);
            return;
        }

        // Clear the bet outcome and rolled number from any previous bet
        setBetOutcome('');
        setRolledNumber('');

        setBetOption(option === 0 ? 'Low' : 'High'); // Save the bet option

        const gameContract = new web3.eth.Contract(HuddlzDiceGameABI, huddlzDiceGameAddress);
        try {
            const response = await gameContract.methods.placeBet(web3.utils.toWei(betAmount, 'ether'), option).send({ from: account });
            setTransactionHash(response.transactionHash);
            setDisplayTransaction(true);
            setTimeout(() => setDisplayTransaction(false), 5000); // Hide the transaction message after 5 seconds
            const betId = response.events.BetPlaced.returnValues.betId;
            setLatestBetId(betId);
            displayMessage(`Bet placed successfully with bet ID: ${betId}`);
            fetchUserBalance(); // Refresh user balance
        } catch (error) {
            displayMessage("Error placing bet. Please try again.", true);
        }
    };

    const fetchBetResult = async (betId, retryCount) => {
        if (retryCount >= 30) {
            setIsLoading(false); // Stop loading after max retries
            displayMessage("Unable to fetch result after multiple attempts.", true);
            return;
        }

        try {
            const gameContract = new web3.eth.Contract(HuddlzDiceGameABI, huddlzDiceGameAddress);
            const result = await gameContract.methods.bets(betId).call();
            const rolledNum = parseInt(result.roll, 10);

            if (rolledNum > 0) {
                setIsLoading(false); // Stop loading when a valid rolled number is fetched
                const option = parseInt(result.option, 10);
                const winCondition = (option === 0 && rolledNum <= 4999) || (option === 1 && rolledNum >= 5000);
                setRolledNumber(rolledNum);
                setBetOutcome(result.settled && winCondition ? "Win" : "Lose");
                displayMessage(`Bet outcome: ${result.settled && winCondition ? "Win" : "Lose"}, Rolled number: ${rolledNum}, Bet amount: ${web3.utils.fromWei(result.amount, 'ether')} HUDL`, !winCondition, 0);
            } else {
                // Retry after a delay if rolled number is 0
                setTimeout(() => fetchBetResult(betId, retryCount + 1), 2000); // Increase retryCount and retry after 2 seconds
            }
        } catch (error) {
            setIsLoading(false); // Ensure loading is stopped in case of an error
            console.error("Error fetching bet result:", error);
            displayMessage("Failed to fetch bet result. Please try again.", true);
        }
    };

    return (
        <div className="DiceGame">
            <div className="DiceGame-container">
                <h2 className="DiceGame-title">Huddlz Dice Game</h2>
                <p>Guess whether the next number will be low (0-4999) or high (5000-9999).</p>
                {isLoading && (
                    <div className="dice-roll">
                        {/* You can use an emoji or an image of a dice */}
                        🎲
                        <p>Rolling...</p>
                    </div>
                )}
                {betOutcome && rolledNumber !== undefined && (
                    <p className="DiceGame-result">
                        Bet Outcome: {betOutcome}, Rolled Number: {rolledNumber}, You bet {betOption}
                    </p>
                )}
                {!account ? (
                    <button onClick={connectWallet} className="btn">Connect Wallet</button>
                ) : (
                    <>
                        <p>Wallet Connected: <span className="DiceGame-walletConnected">{formatAddress(account)}</span> - Balance: {userBalance} HUDL</p>
                        <input
                            type="number"
                            min="100"
                            max="10000"
                            value={betAmount}
                            onChange={(e) => {
                                const value = Math.min(e.target.valueAsNumber, 10000);
                                setBetAmount(value.toString());
                            }}
                            placeholder="Bet Amount"
                            required
                            className="DiceGame-input"
                        />
                        <div className="flex justify-center gap-4 mt-4">
                            {!isApproved && <button onClick={handleApprove} className="btn">Approve</button>}
                            <button onClick={() => handlePlaceBet(1)} className="btn btn-green">Bet High</button>
                            <button onClick={() => handlePlaceBet(0)} className="btn btn-red">Bet Low</button>
                        </div>
                    </>
                )}
                {gameMessage.message && (
                    <div className={`DiceGame-message ${gameMessage.isError ? "error" : "success"}`}>{gameMessage.message}</div>
                )}
                {transactionHash && displayTransaction && (
                    <div className="DiceGame-transaction">
                        Transaction: <a href={`https://bscscan.com/tx/${transactionHash}`} target="_blank" rel="noopener noreferrer">View on BSCScan</a>
                    </div>
                )}
            </div>
        </div>
    );
}

export default DiceGame;
